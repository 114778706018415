/* jshint ignore:start */
/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

var pageHeight;

var menuHeight = function() {
  setTimeout(function(){
    pageHeight = document.documentElement.scrollHeight;
    jQuery('.menu-primary-container').height(pageHeight);
  },500);
}

var rss_slick = function() {

  jQuery('.feed__rss').find('ul').slick({
    arrows: true,
    dots: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    vertical: true,
    verticalSwiping: true,
    adaptiveHeight: true
  });

};

var menuSetup = function() {

  jQuery('.menu-item-has-children').addClass('show-children');

  jQuery('#menu-sitemap').on('click','.menu-item-has-children > a',function(e){
    e.preventDefault();

    if ( jQuery(this).parent().hasClass('show-children') ) {
      jQuery(this).parent().removeClass('show-children');
    } else {
      jQuery(this).parent().addClass('show-children');
    }

  });

  jQuery('.toggle-sub-menu').on('click',function(e){
    e.preventDefault();

    if ( jQuery('body').hasClass('show-sub') ) {
        jQuery('body').removeClass('show-sub');
        jQuery(this).text('View Menu');
    } else {
      jQuery('body').addClass('show-sub');
      jQuery(this).text('Hide Menu');
    }
  });

  jQuery('.navbar-toggle').on('click',function(e){
    e.preventDefault();

    if ( jQuery('body').hasClass('show-menu') ) {
        jQuery('body').removeClass('show-menu');
        //jQuery(this).attr('aria-expanded','false');
    } else {
      jQuery('body').addClass('show-menu');
      //jQuery(this).attr('aria-expanded','true');
    }
  });


  jQuery('.btn-feed--twitter').on('click',function(e){
    e.preventDefault();

    if ( jQuery('body').hasClass('show-twitter') ) {
      jQuery('body').removeClass('show-twitter');
      jQuery(this).find('.overlay__feed__buttons--extra').html('OPEN<br>FEED');
      jQuery('.feed__twitter').attr('aria-expanded','false');
    } else {
      jQuery(this).find('.overlay__feed__buttons--extra').html('EXIT<br>FEED');
      jQuery('body').removeClass('show-rss');
      jQuery('.feed__rss').attr('aria-expanded','false');
      jQuery('body').addClass('show-twitter');
      jQuery('.feed__twitter').attr('aria-expanded','true');
    }
  });

  jQuery('.btn-feed--rss').on('click',function(e){
    e.preventDefault();

    if ( jQuery('body').hasClass('show-rss') ) {
      jQuery('body').removeClass('show-rss');
      jQuery('.feed__rss').attr('aria-expanded','false');
    } else {
      jQuery('body').removeClass('show-twitter');
      jQuery('.feed__twitter').attr('aria-expanded','false');
      jQuery('body').addClass('show-rss');
      jQuery('.feed__rss').attr('aria-expanded','true');
    }

    if ( jQuery('body').hasClass('page-template-template-subscribe') ) {
      jQuery('subs-check')
    }

    if (  jQuery('.feed__rss').find(".slick-initialized").length > 0) {
      jQuery('.feed__rss').find('ul').slick('unslick');

      setTimeout(function(){
        rss_slick();
      },100);
    } else {
      rss_slick();
    }

  });



};

var carousel = function() {
  if ( jQuery('.slider_main').length > 0 ) {
    jQuery('.slider_main').slick({
      arrows: true,
      dots: true,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 768,
              settings: {
                arrows: false
              }
        }
      ]
    });
  }
}

var mainFn = function(){

  didScroll = false;

  jQuery(window).scroll(function() {
    didScroll = true;
  });

  if( jQuery("#next").length > 0 ){

    setInterval(function() {
      if ( didScroll ) {
          didScroll = false;

          if ( jQuery(document).scrollTop() <= jQuery('#next').offset().top ) {
            jQuery('html').removeClass('scrollpoint');
          } else if( ( jQuery(document).height() - jQuery(window).height() - jQuery(".footer").outerHeight() ) < jQuery(document).scrollTop() ){
            jQuery('html').removeClass('scrollpoint');
          } else {
            jQuery('html').addClass('scrollpoint');
          }
      }

    },0);

  }

  var mndFileds=new Array('LEADCF9','Last Name');
    var fldLangVal=new Array('Organisation','Last Name');
    var name='';
    var email='';

    function checkMandatory() {
    for(i=0;i<mndFileds.length;i++) {
      var fieldObj=document.forms['WebToLeads1924608000000181001'][mndFileds[i]];
      if(fieldObj) {
      if (((fieldObj.value).replace(/^\s+|\s+$/g, '')).length==0) {
       if(fieldObj.type =='file')
        {
         alert('Please select a file to upload');
         fieldObj.focus();
         return false;
        }
      alert(fldLangVal[i] +' cannot be empty');
              fieldObj.focus();
              return false;
      }  else if(fieldObj.nodeName=='SELECT') {
             if(fieldObj.options[fieldObj.selectedIndex].value=='-None-') {
        alert(fldLangVal[i] +' cannot be none');
        fieldObj.focus();
        return false;
         }
      } else if(fieldObj.type =='checkbox'){
       if(fieldObj.checked == false){
        alert('Please accept  '+fldLangVal[i]);
        fieldObj.focus();
        return false;
         }
       }
       try {
           if(fieldObj.name == 'Last Name') {
        name = fieldObj.value;
          }
      } catch (e) {}
        }
    }
       }

       var mndFileds=new Array('First Name','Last Name','Email','CONTACTCF11');
       var fldLangVal=new Array('First name','Last name','E-mail address','How many people share with');
       var name='';
       var email='';

      function checkMandatory() {
       for(i=0;i<mndFileds.length;i++) {
         var fieldObj=document.forms['WebToContacts2340540000000171001'][mndFileds[i]];
         if(fieldObj) {
           if (((fieldObj.value).replace(/^\s+|\s+$/g, '')).length==0) {
            if(fieldObj.type =='file')
               {
                alert('Please select a file to upload.');
                fieldObj.focus();
                return false;
               }
           alert(fldLangVal[i] +' cannot be empty');
                     fieldObj.focus();
                     return false;
           }  else if(fieldObj.nodeName=='SELECT') {
                    if(fieldObj.options[fieldObj.selectedIndex].value=='-None-') {
               alert(fldLangVal[i] +' cannot be none');
               fieldObj.focus();
               return false;
              }
           } else if(fieldObj.type =='checkbox'){
               if(fieldObj.checked == false){
               alert('Please accept  '+fldLangVal[i]);
               fieldObj.focus();
               return false;
              }
            }
            try {
                if(fieldObj.name == 'Last Name') {
               name = fieldObj.value;
                  }
           } catch (e) {}
           }
       }
        }




    jQuery('a[href*="#"]:not([href="#"])').click(function() {
      if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
        var target = jQuery(this.hash);
        target = target.length ? target : jQuery('[name=' + this.hash.slice(1) +']');
        if (target.length) {
          jQuery('html, body').animate({
            scrollTop: target.offset().top
          }, 1000);
          return false;
        }
      }
    });

    if ( window.location.hash === '#subscribe' ) {
      jQuery('#subscribe-free').modal('show');
    }

};



var checkboxForSubs = function() {

  if ( jQuery('body').hasClass('page-template-template-subscribe') ) {

    function validate() {
      var isValid = true;
      jQuery('.frm-check').each(function() {
        if ( jQuery(this).val() === '' )
            isValid = false;
      });
      return isValid;
    };

    jQuery('.frm-check').keyup(function() {

      if ( validate() ) {
        jQuery('#subs-check').prop('checked', true);
      } else {
        jQuery('#subs-check').prop('checked', false);
      }

    });

  }

};

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        mainFn();
        menuSetup();
        carousel();
        checkboxForSubs();
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

jQuery(window).resize(menuHeight);
/* jshint ignore:end */
